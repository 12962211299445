import React from 'react'

const LinksListWrapper = ({headerItems, linkItems, ...props}) => {
  return (
    <div className='links-list-wrapper'>
      <div className='links-list'>
        <h3 className='type-section headers'>
          {headerItems && headerItems.map((heading) => (
            <span key={heading._key} className={heading.outdented && '-outdented'}>{heading.title}</span>
          ))}
        </h3>
        {props.children}
      </div>
    </div>
  )
}

export default LinksListWrapper
