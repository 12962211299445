import React, {useState, useEffect, useRef} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {imageUrlFor} from '../lib/image-url';
import {buildImageObj, inBrowser} from '../lib/helpers';
import {window} from 'browser-monads'
import SEO, {siteThemes} from './seo';


export const query = graphql`
    query AboutImageQuery {
        page: sanityAboutPage(_id: {regex: "/(drafts.|)aboutPage/"}) {
            imageLink
            book
            image {
                alt
                caption
                asset {
                    _id
                    metadata {
                        palette {
                          dominant {
                            background
                          }
                        }
                    }                     
                }
            }
        }
    }
`

const AboutImage = () => {
    return (
        <StaticQuery
            query={query}
            render={
                (data) => {
                    const page = data.page
                    const image = page.image;
                    const [isFullscreen, setIsFullscreen] = useState(false);
                    const [cursorActive, setCursor] = useState(false);
                    const imageStrip = useRef();
                    const handleFullScreen = (e) => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          });
                        setIsFullscreen(!isFullscreen);
                        setCursorPosition(e);
                    };
                    const handleCursor = () => {
                        setCursor(!cursorActive);
                    }
                    const handleMouseMove = (e) => {
                        if (isFullscreen) {
                            setCursorPosition(e);
                        }
                    }
                    const setCursorPosition = (e) => {
                        const cursor = inBrowser() ? document.getElementsByClassName('image-cursor')[0] : undefined;
                        const height = cursor.clientHeight;
                        const width = cursor.clientWidth;
                        cursor.style.top = `${e.clientY - (height / 2)}px`;
                        cursor.style.left = `${e.clientX - (width / 2)}px`;                        
                    }
                    useEffect(() => {
                        const cursor = inBrowser() ? document.getElementsByClassName('image-cursor')[0] : undefined;
                        cursorActive ? cursor.classList.add('-active') : cursor.classList.remove('-active');
                        if (isFullscreen && inBrowser()) {
                            document.body.classList.add('-fullscreen');
                        } else {
                            if (inBrowser()) {
                                document.body.classList.remove('-fullscreen');
                            }
                        } 
                    });
                    return (
                        <>
                            <section ref={imageStrip} className={`about-image${isFullscreen ? ' -fullscreen' : ''}`}
                                onClick={(e) => handleFullScreen(e)}
                                onMouseMove={(e) => {
                                    handleMouseMove(e);
                                }}
                                onMouseEnter={() => handleCursor()}
                                onMouseLeave={() => handleCursor()}
                                style={{backgroundImage: `url('${imageUrlFor(buildImageObj(image)).width(2800).quality(80).url()}')`, backgroundColor: image.asset.metadata.palette.dominant.background}}
                            >
                                <p className="type-subheading link -credit"><a href={page.imageLink} target="_blank" onMouseEnter={() => handleCursor()} onMouseLeave={() => handleCursor()}>Photo by<br/>{image.caption}</a></p>
                                <div className="image-cursor"></div>
                                <div className="indicator"></div>
                                <p className="eyes">👀</p>
                            </section>
                        </>
                    )
                }
            }
        />
    )
}

export default AboutImage;