import React from 'react'

const LinkListItems = ({links}) => {
  return (
    links.length ? (
      <ul className='list'>
        {
          links.map((link) => (
            <>
              <li className='listitem type-body' key={link._key}>{link.url ? <a href={link.url} className='link' target='_blank'>{link.title}</a> : `${link.title}`}
                {link.description && <p className='link-description'>{link.description}</p>}
              </li>
            </>

          ))
        }
      </ul>
    ) : null
  )
}

export default LinkListItems
