import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LayoutContainer from '../containers/layout'
import {getSEOMetadata} from '../lib/helpers'
import Layout from '../components/layout'
import BlockContent from '../components/block-content'
import AboutLinksList from '../components/about-links-list'
import Article from '../components/article'
import ContentSection from '../components/content-section'
import ContactDetails from '../components/contact-details'
import AboutImage from '../components/about-image'

export const query = graphql`
  query AboutPageQuery {
    page: sanityAboutPage(_id: {regex: "/(drafts.|)aboutPage/"}) {
      title
      instagramUrl
      instagramUsername
      dribbbleUrl
      dribbbleUsername
      email
      phone
      recognitionHeadings {
        _key
        title
        outdented
      }
      recognitionItems {
        _key
        title
        links {
          _key
          title
          url
        }
      }
      clientsHeadings {
        _key
        title
        outdented
      }
      clientsItems {
        _key
        title
        links {
          _key
          title
          url
        }
      }
      _rawIntro
      seoFields {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const AboutPage = props => {
  const {data, errors} = props
  const page = props.data.page
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <LayoutContainer>
      <SEO title={page.title} SEOMetadata={getSEOMetadata(page, '_rawIntro')} />
      <Container>
        <Article>
          <AboutImage />
          <ContactDetails />
          <ContentSection>
            <BlockContent blocks={page._rawIntro || []} />
            <AboutLinksList headerItems={page.recognitionHeadings} linkItems={page.recognitionItems} />
            <AboutLinksList headerItems={page.clientsHeadings} linkItems={page.clientsItems} />
          </ContentSection>
        </Article>
      </Container>
    </LayoutContainer>
  )
}

export default AboutPage
