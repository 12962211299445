import React from 'react'
import LinkListItems from './link-list-items'
import LinksListWrapper from './links-list-wrapper'

const AboutLinksList = ({headerItems, linkItems}) => {
  return (
    <LinksListWrapper headerItems={headerItems}>
      <ul className='link-group'>
        {
          linkItems.map((item) => (
            <li className='group-item' key={item._key}>
              <h3 className='type-section'>{item.title}</h3>
              <LinkListItems links={item.links} />
            </li>
          ))
        }
      </ul>
    </LinksListWrapper>
  )
}

export default AboutLinksList
